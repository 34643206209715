<template>
  <div>
    <navigation />
    <div class="flix-container">
      <div class="flix-text-danger">{{ $t('message.signIn') }}: {{ $t('message.dashboard') }}</div>
      <h1 class="flix-html-h2">{{ $t('message.pleaseConfirmEmail', { name: variables.user.email }) }}</h1>
      <div class="flix-form-group">
        <div class="flix-alert flix-alert-warning">
          {{ $t('tips')[0] }}
        </div>
      </div>
      <div class="flix-form-group flix-text-right">
        <div class="flix-form-group">{{ $t('message.getValidationEmail') }}</div>
        <editBtn v-if="validationLink" :onClick="function(){ setResendLink() }">
          <template v-slot:text>
            {{ $t('message.resend', {name: $t('reminder.email')}) }}
          </template>
        </editBtn>
        <div v-else class="flix-alert flix-alert-success">{{ $t('message.successfullSendet') }} ({{ variables.user.email }})</div>
      </div>
      <div class="flix-form-group">
        <backBtn :onClick="function() { logoutLink = !logoutLink }">
          <template v-slot:text>
            {{ $t("message.close", {name: $tc('message.page', 1)}) }}
          </template>
        </backBtn>
      </div>
      <div class="flix-form-group" v-if="logoutLink">
        <confirmLogout />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    navigation () { return import('@/components/frontend/navigation/navigation') },
    confirmLogout () { return import('@/components/dashboard/logout') }
  },
  props: {},
  data () {
    return {
      validationLink: true,
      logoutLink: false,
      variables: this.$getUserVariables(),
      masked: false
    }
  },
  methods: {
    setResendLink () {
      this.validationLink = false
      this.validation_mail()
    },
    validation_mail (v) {
      var mail = this.$t('email.registerEmail')
      var link = this.$flix_url + this.$router.resolve({ name: 'validateUser', params: { user: this.masked } }).href
      var send = {
        user: this.variables.user.md5_id,
        to: this.variables.user.email,
        title: mail.title,
        text: mail.text.split('{link}').join('<a href="' + link + '">' + link + '</a>')
      }
      this.$flix_post({
        data: send,
        url: 'user/registermail',
        callback: function () { }
      })

      return false
    }
  },
  mounted () {
    this.masked = btoa(this.variables.user.md5_id)
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
